import React from "react";
import { Link } from "gatsby";

import clsx from "clsx"

import { FooterLogo } from "~/components/Icons";

interface Content {
  id: string;
  title: string;
  url: string;
}

interface FooterProps {
  color?: string;
  isLarge?: boolean;
}

const MiddleContent: Content[] = [
  {
    title: "Home",
    url: "/",
    id: "home_link",
  },
  {
    title: "Teacher zone",
    url: "/",
    id: "teacher_zone_link",
  },
  {
    title: "Contact",
    url: "/",
    id: "contact_link",
  },
];

const LastContent: Content[] = [
  {
    title: "Privacy policy",
    url: "/",
    id: "privacy_policy_link",
  },
  {
    title: "FAQ",
    url: "/",
    id: "faq_link",
  },
];

const Footer = ({
  color="ms",
  isLarge=true
}: FooterProps) => {
  const isMs: boolean = color === "ms";

  return (
    <footer className={clsx({
       "bg-purple-900": isMs,
       "bg-indigo-500": !isMs
    })}>
      <div className="m-auto px-5 max-w-4xl xl:max-w-screen-lg">
        {isLarge && (
          <div className="py-7 text-center border-b border-gray-50 md:text-left md:py-0 md:pt-16 md:pb-8 md:flex md:justify-between lg:justify-start">
            <div className="lg:flex lg:flex-col lg:flex-1 mb-8">
              <p className="text-base text-white mb-3">Address</p>
              <p className={clsx("text-sm leading-8",{
                 "text-purple-300": isMs,
                 "text-indigo-300": !isMs
              })}>202 326 6400</p>
              <p className={clsx("text-sm leading-8",{
                 "text-purple-300": isMs,
                 "text-indigo-300": !isMs
              })}>5415 Mark Dabling Blvd,</p>
              <p className={clsx("text-sm leading-8",{
                 "text-purple-300": isMs,
                 "text-indigo-300": !isMs
              })}>Colorado Springs, CO 80918</p>
            </div>
            <div className="lg:flex lg:flex-col lg:flex-1 mb-8">
              <p className="text-base text-white mb-3">Site Map</p>
              {MiddleContent.map(el => (
                <Link className={clsx("block text-sm leading-8", {
                 "text-purple-300": isMs,
                 "text-indigo-300": !isMs
              })} to={el.url} key={`middle_el__${el.id}`}>
                  {el.title}
                </Link>
              ))}
            </div>
            <div className="lg:flex lg:flex-col lg:flex-1">
              <p className="text-base text-white mb-3">Info</p>
              {LastContent.map(el => (
                <Link className={clsx("block text-sm leading-8", {
                 "text-purple-300": isMs,
                 "text-indigo-300": !isMs
              })} to={el.url} key={`last_el__${el.id}`}>
                  {el.title}
                </Link>
              ))}
            </div>
          </div>
        )}
        <div className="text-center text-white flex flex-col justify-center items-center py-7 lg:flex-row lg:justify-between lg:px-0">
          <Link to="/" title="Homepage" className="mb-6 lg:mb-0">
            <FooterLogo  className="w-24 h-7"/>
          </Link>
          <p className="text-sm">
            Material & content copyright © 2022 BSCS Science Learning. All rights
            reserved.
          </p>
          <div />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
