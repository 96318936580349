import React, { ReactNode } from "react";
import "@fortawesome/fontawesome-pro/css/all.min.css"

import SEO from "~/components/seo";

interface AppWrapperProps {
  className?: string;
  children: ReactNode;
  current_step?: number | string;
  lesson?: string;
}

const formatStep = (step: number): string => {
  if (step < 0) {
    return "Lesson at a Glance"
  }

  return `Step ${step}`
}

const AppWrapper = ({ className, children, current_step, lesson }: AppWrapperProps): JSX.Element => {
  const formattedStep: string = current_step !== undefined ? formatStep(current_step) : "";
  const formattedTitle: string | undefined = lesson && `${lesson} ${formattedStep}`;

  return (
    <>
        <SEO title={formattedTitle ? formattedTitle : "User dashboard"} />
        <div className={className ? className : "m-auto"}>
           {children}
        </div>
    </>
  )
};

export default AppWrapper;
